<template>
  <section class="transfer-types">
    <h2 class="transfer-types__heading">Account Management</h2>
    <div class="transfer-types__wrapper">
      <div class="type" @click="$router.push('/account-opening')">
        <div class="type__svg">
          <img
            class="feature__img"
            src="../../assets/images/accounts-icon.svg"
            alt="accounts-icon"
          />
        </div>
        <p class="type__text">
          Account<br />
          Opening
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      accountNumber: ""
    };
  },
  computed: mapState(["userAccounts", "user", "loading"]),
  methods: {}
};
</script>

<style lang="scss" scoped>
.transfer-types {
  margin-inline: auto;
  width: 90%;
  max-width: 35rem;
  &__heading {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    color: #000000;
    text-align: center;
    margin-bottom: 3rem;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
  .type {
    cursor: pointer;
    &:nth-of-type(1) {
      cursor: pointer;
    }
    &:nth-of-type(4) {
      cursor: pointer;
    }
    &__svg {
      background: #ffe4e5;
      padding: 4rem;
      border-radius: 1rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
      }
    }
    &__text {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.05em;
      color: #000000;
      text-align: center;
    }
  }
  // 480px
  @media only screen and (max-width: 30em) {
    .type {
      &__svg {
        padding: 3rem;
      }
    }
  }
}
</style>
